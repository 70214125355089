import React from "react";
import Contacto from "./Contacto";
import Mapa from "./Mapa";
import RedSocial from "./RedSocial";
import "../css/footer.css";

function Footer() {
  return (
    <footer>
      <div className="contenedor-footer">
        <div className="contact">
          <Contacto nombre="Teléfono" valor="387 5161937" />
          <Contacto nombre="Correo" valor="aberturasaranda.admi@gmail.com" />
        </div>
        <div className="mapas">
          <Mapa
            nombre="Sede Central"
            ubicacion="Av. Las Americas 783"
            url="https://maps.app.goo.gl/qVRspGwSM5KQfpnT6"
            urlmapa="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3081.7334808207843!2d-65.38717769838372!3d-24.811209748436212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDQ4JzM3LjgiUyA2NcKwMjMnMDcuOCJX!5e0!3m2!1ses-419!2sar!4v1713997352465!5m2!1ses-419!2sar"
          />
        </div>
        <div className="RedesSociales">
          <RedSocial
            nombre="Facebook"
            url="https://www.facebook.com/aberturasdealuminiodantearanda/"
            imagen="http://aluminioargentino.online/imag/facebook-s-f.png"
          />
          <RedSocial
            nombre="Instagram"
            url="https://www.instagram.com/aberturas_aranda/"
            imagen="http://aluminioargentino.online/imag/instagram-s-f.png"
          />
          <RedSocial
            nombre="TikTok"
            url="https://www.tiktok.com/@aberturasaranda"
            imagen="http://aluminioargentino.online/imag/tiktok-s-f.png"
          />
          <RedSocial
            nombre="Youtube"
            url="https://www.youtube.com/@aberturasaranda"
            imagen="http://aluminioargentino.online/imag/youtube-s-f.png"
          />
          <RedSocial
            nombre="Twitter"
            url="http://aluminioargentino.online/Twitter.com/AberturasAranda"
            imagen="http://aluminioargentino.online/imag/twitter-s-f.png"
          />
          <RedSocial
            nombre="Linkedin"
            url="https://www.linkedin.com/in/aberturas-aranda-/"
            imagen="http://aluminioargentino.online/imag/linkedin-s-f.png"
          />
          <RedSocial
            nombre="WhatsApp"
            url="https://wa.me/5493875161937?text=Hola%20que%20tal%20me%20gustar%C3%ADa%20contactarme%20con%20Aberturas%20Aranda%20:%20"
            imagen="http://aluminioargentino.online/imag/WhatsApp-s-f.png"
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
