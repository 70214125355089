import React from "react";

function CincoEstrellas() {
  return (
    <>
      <h2>Somos una Empresa de 5 estrellas en Opiniones y Recomendaciones.</h2>
     {/*  <img src="https://aberturasaranda.com.ar//imag/5estrellas-sin-fondo.png" alt="" /> */}
    </>
  );
}

export default CincoEstrellas;
